import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';

function App() {

  const title ='Feliz Cumpleaños Elvia Malú';

  return (
    <>
    <br/>
    <div className='columns'>
      <div className='column'>

        <div className="box">
          <div className="card">
            <div className="card-image">
              <figure className="image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/micv-46717.appspot.com/o/micumple%2FInvitacion.jpg?alt=media&token=28d26b89-7703-4b1d-97c4-c9389d22994dg"
                  alt={title}
                  title={title}
                />
              </figure>
            </div>
          </div>
        </div>

      </div>
      <div className='column'>
{/* Start */}

<div className="box">
  <div className="content">
    <h1>{title} <span className="fa fa-birthday-cake fa-lg"></span></h1>
    <hr />

    <h3>¡Hola, familia y amigos! 🎉</h3>
    <p>
      Estamos emocionados de celebrar el cumpleaños de mi mamá y, para hacerlo aún más especial, hemos creado esta página web.
      Aquí podrán contactarnos fácilmente por&nbsp; 
      <a href="https://api.whatsapp.com/send?phone=+529982426490&text=¡Hola!" title="Manda un mensaje por WhatsApp" target="_blank" className="whatsapp info">WhatsApp</a>&nbsp;
      y también encontrar la 
      <a href="https://maps.app.goo.gl/Bqu5RVjc5oiHgpKBA" title="Ubicación" target="_blank" className="maps info">&nbsp;ubicación en Google Maps</a>.
    </p>
    <p>¡Esperamos contar con ustedes en este día tan especial! 💖</p>

    <br /><br /><br />
    <p>
      <ul>
        <li>
          <h3>
            <a href="https://api.whatsapp.com/send?phone=+529982426490&text=¡Hola!" title="Manda un mensaje por WhatsApp" target="_blank" className="whatsapp info">
              <span className="fa fa-whatsapp fa-lg" aria-hidden="true"></span>&nbsp;
              <span>WhatsApp: 99 82 42 64 90</span>
            </a>
          </h3>
        </li>
        <li>
          <h3>
            <a href="https://maps.app.goo.gl/Bqu5RVjc5oiHgpKBA" title="Ubicación" target="_blank" className="maps info">
              <span className="fa fa-map-marker-alt fa-lg" aria-hidden="true"></span>&nbsp;
              <span>Dirección: Vía Láctea No. 2, Jardines de Satélite, Naucalpan.</span>
            </a>
          </h3>
        </li>
        <li>
          <h3>
            <div className="info">
              <span className="fa fa-calendar-alt fa-lg" aria-hidden="true"></span>&nbsp;
              <span>Fecha: 15 de febrero de 2025</span>
            </div>
          </h3>
        </li>
        <li>
          <h3>
            <div className="info">
              <span className="fa fa-clock fa-lg" aria-hidden="true"></span>&nbsp;
              <span>Hora: 2:00 PM</span>
            </div>
          </h3>
        </li>
        <li>
          <h3>
            <div className="info">
              <span className="fa fa-car-side fa-lg" aria-hidden="true"></span>&nbsp;
              <span>Estacionamiento: Sí hay, pero tiene costo por tiempo indefinido.</span>
            </div>
          </h3>
        </li>
        <li>
          <h3>
            <div className="info">
              <span className="fa fa-check-circle fa-lg" aria-hidden="true"></span>&nbsp;
              <span>Confirmación de asistencia: Antes del 20 de enero de 2025. Es un evento para amigos y familiares. Para atenderlos muy bien, agradeceríamos mucho su confirmación.</span>
            </div>
          </h3>
        </li>
      </ul>
    </p>
  </div>
</div>


{/* End */}

          
      </div>
    </div>
    <br/>
    </>
  );
}

export default App;
